<template>
    <a-card>
        <a-page-header
            title="绑定"
        />

        <div class="mt-20">落地页组：{{ name || '-' }}</div>
        <div class="mt-20">
            <a-space>
                <a-input
                    v-model="adLandingPageId"
                    placeholder="请输入站点ID"
                    style="width: 300px"
                />
                <a-button type="primary" @click="handleState">提交</a-button>
            </a-space>
        </div>

        <div class="mt-20">可绑定站点10个，已绑定 <span class="red">{{ dataList.length }}</span> 个</div>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.adLandingPageId"
            :pagination='false'
            class="mt-20"
        >
            <div
                slot="imgUrl"
                slot-scope="text, record"
            >
                <span v-if="record.thumbnail">
                    <img :src="record.thumbnail" class="img" v-if="record.adLandingPageAuditStatus == 'DELETED'">
                    <img :src="record.thumbnail" class="img" v-else @click="handlePreviewOpen(record.thumbnail, 'img')">
                </span>
                <span v-else>-</span>
            </div>
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <a-button type="link" @click="updateState(record)">{{ record.adLandingPageOptStatus == 'SITE_OPT_STATUS_DISABLE' ? '启用' : '禁用' }}</a-button>
            </div>
        </a-table>

        <div class="mt-20">PS：累计访问量和转化率统计数据截止2天前，例如：今天是2023年1月3日，系统统计数据截止2023年1月2日</div>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />

        <!-- 绑定 -->
        <a-modal
            :visible="visible"
            title="绑定落地页预览"
            width="600px"
            @cancel="handleCancel"
        >
            <template slot="footer">
                <a-button
                    type="primary"
                    :loading="isSubmit"
                    :disabled="isSubmit"
                    @click="handleSubmit"
                >
                    绑定
                </a-button>
                <a-button
                    @click="handleCancel"
                >
                    取消绑定
                </a-button>
            </template>
            <div class="content">
                <div class="img-box">
                    <img :src="dataInfo.thumbnail">
                </div>
                <div class="detail">
                    <div class="row">橙子建站状态：{{ filterState(dataInfo.adLandingPageStatus) }}</div>
                    <div class="row">建站名称：
                        <a :href="dataInfo.previewUrl" target="_blank">{{ dataInfo.adLandingPageName }}</a>
                    </div>
                </div>
            </div>
        </a-modal>
    </a-card>
</template>

<script>
import utils from "@/utils/FormatUtil.js";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

const statusList = [
    { id: "ENABLE", label: "已发布, 已生效" },
    { id: "DISABLE", label: "不可用" },
    { id: "DELETED", label: "已删除" },
    { id: "EDIT", label: "未发布, 未生效" },
    { id: "FORBIDDEN", label: "惩罚广告主, 站点下线" },
    { id: "AUDIT_ACCEPTED", label: "审核通过" },
    { id: "AUDIT_REJECTED", label: "审核拒绝" },
    { id: "AUDIT_BANNED", label: "审核封禁" },
    { id: "AUDIT_DOING", label: "审核中" },
]

export default {
    components: {
        previewModal
    },
    data() {
        return {
            id: this.$route.query.id,
            name: this.$route.query.name,
            adPlatformAccountId: this.$route.query.adPlatformAccountId,
            adLandingPageId: '',
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '站点创建时间',
                    dataIndex: 'ctime',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '橙子建站状态',
                    customRender:(text, row) => {
                        let label = ''
                        try {
                            label = (statusList.filter(item => item.id == row.adLandingPageStatus))[0].label
                        } catch(res) {
                            label = '-'
                        }
                        return label
                    }
                },
                {
                    align: 'center',
                    title: '站点启用状态',
                    dataIndex: 'adLandingPageOptStatus',
                    customRender:(text, row) => {
                        return row.adLandingPageOptStatus == 'SITE_OPT_STATUS_ENABLE' ? '启用' : row.adLandingPageOptStatus == 'SITE_OPT_STATUS_DISABLE' ? '禁用' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '站点缩略图',
                    scopedSlots: { customRender: 'imgUrl' },
                },
                {
                    align: 'center',
                    title: '建站名称',
                    dataIndex: 'adLandingPageName',
                    customRender:(text, row) => {
                        let txt = <div><a on-click={() => this.handlePreviewOpen(row.previewUrl, 'url')}>{ row.adLandingPageName }</a></div>
                        return row.adLandingPageAuditStatus == 'DELETED' ? row.name : txt
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text ||'-'
                    }
                },
                {
                    align: 'center',
                    title: '累计访问量',
                    dataIndex: 'viewCount',
                    customRender: (text, row) => {
                        return (row.viewCount ?? '') !== '' ? utils.humanReadable(row.viewCount, 2) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化率',
                    dataIndex: 'convertRate',
                    customRender: (text, row) => {
                        return (row.convertRate ?? '') !== '' ? `${(row.convertRate).toFixed(2)}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            dataList: [],
            previewVisible: false,
            previewSrc: '',
            visible: false,
            relationId: '',
            dataInfo: {},
            isSubmit: false,
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        filterState(state) {
            let arr = (statusList.filter(item => item.id == state))
            return arr.length > 0 ? (statusList.filter(item => item.id == state))[0].label : '-'
        },
        getDataList() {
            this.loading = true
            this.$api.core.materialManage.getAdLandingPageGroupDetail(this.id).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.relationAdLandingPageList || []
                } else {
                    this.dataList = []
                    console.error(`获取列表失败，${res.message}`)
                }
            })
        },
        handlePreviewOpen(url, type) {
            if(type == 'url') {
                window.open(url)
            } else {
                this.previewVisible = true
                this.previewSrc = url
            }
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        handleState() {
            if(!this.adLandingPageId) {
                this.$message.error('请输入站点ID')
                return
            }
            let params = {
                adPlatformAccountId: this.adPlatformAccountId,
                adLandingPageId: this.adLandingPageId,
            }
            this.$api.core.materialManage.getByAdLandingPageId(params).then((res) => {
                if(res.code == 200) {
                    this.visible = true
                    this.dataInfo = res.data
                } else {
                    this.$message.error(`操作失败，请确保站点ID正确`)
                }
            })
        },
        updateState(record) {
            let params = {
                adLandingPageOptStatus: record.adLandingPageOptStatus == 'SITE_OPT_STATUS_ENABLE' ? 'SITE_OPT_STATUS_DISABLE' : 'SITE_OPT_STATUS_ENABLE',
                relationId: record.relationId,
            }

            this.$api.core.materialManage.updateSiteStatus(params).then((res) => {
                if(res.code == 200) {
                    this.getDataList()
                    this.$message.success(`操作成功`)
                } else {
                    this.$message.error(`操作失败，${res.message}`)
                }
            })
        },
        handleSubmit() {
            let params = {
                hjkAdLandingPageGroupId: this.id,
                adLandingPageId: this.adLandingPageId,
            }
            this.isSubmit = true
            this.$api.core.materialManage.addSite(params).then((res) => {
                this.isSubmit = false
                if(res.code == 200) {
                    this.getDataList()
                    this.handleCancel()
                    this.$message.success(`操作成功`)
                } else {
                    this.$message.error(`操作失败，${res.message}`)
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.mt-20 {
    margin-top: 20px;
}
.img {
    width: 60px;
}
.content {
    display: flex;
    .img-box {
        width: 120px;
        background-color: rgba(0, 0, 0, .05);
        border-radius: 5px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .detail {
        flex: 1;
        margin-left: 20px;
        .row {
            margin-bottom: 10px;
        }
    }
}
</style>
